import { MenuProps } from "antd";
import { ImProfile } from "react-icons/im";
import { BiUserPin } from "react-icons/bi";
import { RiGroupFill, RiHealthBookLine } from "react-icons/ri";
import { LiaRouteSolid } from "react-icons/lia";
import { IoCashOutline } from "react-icons/io5";
import { GrUserWorker, GrGroup, GrDashboard } from "react-icons/gr";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { PiCashRegister, PiMoneyThin, PiTruckThin } from "react-icons/pi";
import { SiBnbchain, SiPrivateinternetaccess } from "react-icons/si";
import {
  FaCarBurst,
  FaChartLine,
  FaHelmetSafety,
  FaTruckPlane,
} from "react-icons/fa6";
import {
  MdWork,
  MdOutlineCategory,
  MdOutlineMapsHomeWork,
} from "react-icons/md";
import {
  CiGrid41,
  CiHome,
  CiMoneyBill,
  CiUser,
  CiShoppingCart,
  CiChat1,
} from "react-icons/ci";
import { FaTruck } from "react-icons/fa";
import { TbCodeAsterisk } from "react-icons/tb";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { HiMiniAdjustmentsHorizontal } from "react-icons/hi2";
import { Link } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];
export interface IMenu {
  title: React.ReactNode;
  label: React.ReactNode;
  key: React.Key;
  icon?: React.ReactNode;
  children?: MenuItem[];
}

export interface IApplication {
  companyName: string;
  menu: MenuItem[];
}

export const application: IApplication = {
  companyName: "Associação dos Transportadores de Minério de Sarzedo",
  menu: [
    {
      title: "Início",
      label: <Link to={"/"}>Início</Link>,
      key: "home",
      icon: <CiHome />,
    },
    {
      title: "Analytics",
      label: <Link to={"/"}>Analytics</Link>,
      key: "analytics",
      icon: <GrDashboard />,
      children: [
        {
          title: "Financeiro",
          label: <Link to={"/financesAnalytics"}>Financeiro</Link>,
          key: "financesAnalytics",
          icon: <FaChartLine />,
        },
        {
          title: "Fluxo de Caixa",
          label: <Link to={"/cashFlowsAnalytics"}>Fluxo de Caixa</Link>,
          key: "cashFlowsAnalytics",
          icon: <FaChartLine />,
        },
        {
          title: "Classificação Financeira",
          label: (
            <Link to={"/financialCategoriesAnalytics"}>
              Classificação Financeira
            </Link>
          ),
          key: "financialCategoriesAnalytics",
          icon: <FaChartLine />,
        },
      ],
    },
    {
      title: "Cadastro",
      label: "Cadastro",
      key: "registration",
      icon: <CiGrid41 />,
      children: [
        {
          title: "Empresa",
          label: <Link to={"/companies"}>Empresa</Link>,
          key: "companies",
          icon: <MdOutlineMapsHomeWork />,
        },
        {
          title: "Fornecedores",
          label: <Link to={"/suppliers"}>Fornecedores</Link>,
          key: "suppliers",
          icon: <CiShoppingCart />,
        },
        {
          title: "Clientes",
          label: <Link to={"/customers"}>Clientes</Link>,
          key: "customers",
          icon: <RiGroupFill />,
        },
        {
          title: "Associados",
          label: <Link to={"/associates"}>Associados</Link>,
          key: "associates",
          icon: <GrGroup />,
        },
        {
          title: "Veículos",
          label: <Link to={"/vehicles"}>Veículos</Link>,
          key: "vehicles",
          icon: <PiTruckThin />,
        },
        {
          title: "Categoria Veículos",
          label: <Link to={"/vehicleCategories"}>Categoria Veículos</Link>,
          key: "vehicleCategories",
          icon: <FaTruckPlane />,
        },
        {
          title: "Classificação Financeira",
          label: (
            <Link to={"/financialCategories"}>Classificação Financeira</Link>
          ),
          key: "financialCategories",
          icon: <MdOutlineCategory />,
        },
        {
          title: "Cargos",
          label: <Link to={"/positions"}>Cargos</Link>,
          icon: <MdWork />,
          key: "positions",
        },
        {
          title: "Tipo de Documento",
          label: <Link to={"/documentTypes"}>Tipo de Documento</Link>,
          icon: <TbCodeAsterisk />,
          key: "documentTypes",
        },
        {
          title: "Forma de Pagamento",
          label: <Link to={"/paymentTypes"}>Forma de Pagamento</Link>,
          icon: <TbCodeAsterisk />,
          key: "paymentTypes",
        },
      ],
    },
    {
      title: "Mercado",
      label: "Mercado",
      key: "supplyChain",
      icon: <SiBnbchain />,
      children: [
        {
          title: "Fechamento Associado",
          label: (
            <Link to={"/associateMonthlyBilling"}>Fechamento Associado</Link>
          ),
          key: "associateMonthlyBilling",
          icon: <FaTruck />,
        },
        {
          title: "Relatórios",
          label: <Link to={"/supplyChainReports"}>Relatórios</Link>,
          icon: <HiOutlineDocumentReport />,
          key: "supplyChainReports",
          children: [
            {
              title: "Relatório de Fechamento Associado",
              label: (
                <Link to={"/reportAssociateMonthlyBilling"}>
                  Relatório de Fechamento Associado
                </Link>
              ),
              key: "reportAsociateMonthlyBilling",
            },
          ],
        },
      ],
    },
    {
      title: "Financeiro",
      label: "Financeiro",
      key: "finance",
      icon: <CiMoneyBill />,
      children: [
        {
          title: "Contas a Pagar",
          label: "Contas a Pagar",
          key: "payable",
          icon: <IoCashOutline />,
          children: [
            {
              title: "Contas a Pagar",
              label: <Link to={"/accountsPayable"}>Contas a Pagar</Link>,
              key: "accountsPayable",
              icon: <IoCashOutline />,
            },
            {
              title: "Relatório",
              label: <Link to={"/accountsPayableReport"}>Relatórios</Link>,
              key: "accountsPayableReport",
              icon: <PiCashRegister />,
            },
          ],
        },
        {
          title: "Contas a Receber",
          label: "Contas a Receber",
          key: "receivable",
          icon: <IoCashOutline />,
          children: [
            {
              title: "Contas a Recebe",
              label: <Link to={"/accountsReceivable"}>Contas a Receber</Link>,
              key: "accountsReceivable",
              icon: <IoCashOutline />,
            },
            {
              title: "Relatório",
              label: <Link to={"/accountsReceivableReport"}>Relatórios</Link>,
              key: "accountsReceivableReport",
              icon: <PiCashRegister />,
            },
          ],
        },
        {
          title: "Fluxo de Caixa",
          label: <Link to={"/cashFlow"}>Fluxo de Caixa</Link>,
          key: "cashFlow",
          icon: <PiCashRegister />,
        },
        // {
        //   title: "Relatório",
        //   label: "Relatório",
        //   key: "report-finance",
        //   icon: undefined,

        // },
      ],
    },
    {
      title: "RH",
      label: "RH",
      key: "humanResources",
      icon: <BsFillPersonVcardFill />,
      children: [
        {
          title: "Funcionários",
          label: <Link to={"/employees"}>Funcionários</Link>,
          icon: <GrUserWorker />,
          key: "employees",
        },
        {
          title: "Relatórios",
          label: <Link to={"/reportEmployees"}>Relatórios</Link>,
          icon: <HiOutlineDocumentReport />,
          key: "reportEmployees",
          children: [
            {
              title: "Funcionários",
              label: <Link to={"/employeesReport"}>Funcionários</Link>,
              key: "employeesReport",
            },
            {
              title: "Funcionários Por Associado",
              label: (
                <Link to={"/employeesWithAssociatesReport"}>
                  Funcionários Por Associado
                </Link>
              ),
              key: "employeesWithAssociatesReport",
            },
          ],
        },
      ],
    },
    {
      title: "TST",
      label: "TST",
      key: "safetyAtWork",
      icon: <FaHelmetSafety />,
      children: [
        {
          title: "Controle de Documentos",
          label: <Link to={"/employeeDocuments"}>Controle de Documentos</Link>,
          icon: <RiHealthBookLine />,
          key: "employeeDocuments",
        },
        {
          title: "Sinistro",
          label: <Link to={"/accidents"}>Sinistro</Link>,
          icon: <FaCarBurst />,
          key: "accidents",
        },
      ],
    },
    // {
    //   title: "Parâmetros do Sistema",
    //   label: "Parâmetros do Sistema",
    //   key: "internalParameters",
    //   icon: <HiMiniAdjustmentsHorizontal />,
    //   children: [],
    // },
    {
      title: "Configuração",
      label: "Configuração",
      key: "configuration",
      icon: <SiPrivateinternetaccess />,
      children: [
        {
          title: "Perfil",
          label: <Link to={"/profile"}>Perfil</Link>,
          icon: <ImProfile />,
          key: "profile",
        },
        {
          title: "Usuário",
          label: <Link to={"/users"}>Usuário</Link>,
          icon: <CiUser />,
          key: "users",
        },
        {
          title: "Permissões",
          label: <Link to={"/permissions"}>Permissões</Link>,
          icon: <BiUserPin />,
          key: "permissions",
        },
        {
          title: "Rotas",
          label: <Link to={"/parameters"}>Rotas</Link>,
          icon: <LiaRouteSolid />,
          key: "parameters",
        },
      ],
    },
  ],
};
