import dayjs from "dayjs";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import { ActionGrid } from "../../../components/action-grid";
import { SaveOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useForm } from "antd/es/form/Form";
import { NumericFormat } from "react-number-format";

export const ColumnsTable: any = ({
  roles,
  handleEdit,
}: {
  roles: any;
  handleEdit: (data: any) => void;
}) => [
  {
    title: "Competência do Pagamento",
    width: 200,
    render: (_: any, record: any) => {
      if (
        record?.accountsPayable?.competenceMonth &&
        record?.accountsPayable?.competenceYear
      ) {
        return (
          <Typography.Text>
            {dayjs(
              String(
                `${record?.accountsPayable?.competenceMonth}/${record?.accountsPayable?.competenceYear}`
              ),
              "M/YYYY"
            ).format("MM/YYYY")}
          </Typography.Text>
        );
      }
      if (
        record?.accountReceiveble?.competenceMonth &&
        record?.accountReceiveble?.competenceYear
      ) {
        return (
          <Typography.Text>
            {dayjs(
              String(
                `${record?.accountReceiveble?.competenceMonth}/${record?.accountReceiveble?.competenceYear}`
              ),
              "M/YYYY"
            ).format("MM/YYYY")}
          </Typography.Text>
        );
      }
    },
  },
  {
    title: "Vencimento",
    width: 150,
    render: (_: any, record: any) =>
      (record?.accountsPayable?.dueDate && (
        <Typography.Text>
          {dayjs(record?.accountsPayable?.dueDate).format("DD/MM/YYYY")}
        </Typography.Text>
      )) ||
      (record?.accountReceiveble?.dueDate && (
        <Typography.Text>
          {dayjs(record?.accountReceiveble?.dueDate).format("DD/MM/YYYY")}
        </Typography.Text>
      )),
  },
  {
    title: "Classificação Financeira",
    width: 200,
    render: (value: any, record: any) => {
      return (
        <Typography.Text>
          {record?.accountsPayable?.financialCategory?.description ??
            record?.accountsReceivable?.financialCategory?.description}
        </Typography.Text>
      );
    },
  },
  {
    title: "Fornecedor",
    dataIndex: "supplier",
    key: "supplier",
    width: 300,
    render: (value: any, record: any) =>
      (record?.accountsReceivable && (
        <Typography.Text>
          {record?.accountsReceivable?.associate?.name}
        </Typography.Text>
      )) ||
      (record?.accountsPayable && (
        <Typography.Text>
          {record?.accountsPayable?.supplier?.name}
        </Typography.Text>
      )),
  },
  {
    title: "Geral",
    className: "bg-geral",
    children: [
      {
        title: "Crédito",
        dataIndex: "accountsReceivable",
        key: "accountsReceivable",
        align: "end",
        width: 100,
        render: (value: any) =>
          value?.amount ? (
            <Typography.Text type="success">
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value?.amount || 0)}
            </Typography.Text>
          ) : (
            ""
          ),
      },
      {
        title: "Débito",
        dataIndex: "accountsPayable",
        key: "accountsPayable",
        align: "end",
        width: 100,
        render: (value: any) =>
          value?.amount ? (
            <Typography.Text type="danger">
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value?.amount || 0)}
            </Typography.Text>
          ) : (
            ""
          ),
      },
    ],
  },
  {
    title: "ATMS",
    className: "bg-atms",
    children: [
      {
        title: "Crédito",
        dataIndex: "accountsReceivableAtms",
        key: "accountsReceivableAtms",
        align: "end",
        width: 100,
        render: (value: any) =>
          value ? (
            <Typography.Text type="success">
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value || 0)}
            </Typography.Text>
          ) : (
            ""
          ),
      },
      {
        title: "Débito",
        dataIndex: "accountsPayableAtms",
        key: "accountsPayableAtms",
        align: "end",
        width: 100,
        render: (value: any) =>
          value ? (
            <Typography.Text type="danger">
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value || 0)}
            </Typography.Text>
          ) : (
            ""
          ),
      },
      {
        title: "Operação",
        dataIndex: "operation",
        align: "end",
        width: 100,
        render: (value: any, record: any) => {
          
          
          return (
            <Flex gap={2}>
              <ActionGrid handleEdit={()=> handleEdit(record)} value={value} roles={roles} />
            </Flex>
          );
        },
      },
    ],
  },
];

export const TableSummary = ({ dataSource }: { dataSource: any[] }) => {
  return (
    <Table.Summary fixed={"bottom"}>
      <Table.Summary.Row>
        {/* <Table.Summary.Cell index={new Date().getTime()} /> */}
        <Table.Summary.Cell
          index={new Date().getTime()}
          align="end"
          colSpan={4}
        >
          <Typography.Text strong>Total</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} align="end">
          {
            <Typography.Text strong type="success">
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(
                (dataSource || []).reduce(
                  (prev, curr?: any) =>
                    prev + Number(curr?.accountsReceivable?.amount || 0),
                  0
                )
              )}
            </Typography.Text>
          }
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} align="end">
          {
            <Typography.Text strong type="danger">
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(
                (dataSource || []).reduce(
                  (prev, curr?: any) =>
                    prev + Number(curr?.accountsPayable?.amount || 0),
                  0
                )
              )}
            </Typography.Text>
          }
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} align="end">
          {
            <Typography.Text strong type="success">
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(
                (dataSource || []).reduce(
                  (prev, curr?: any) =>
                    prev + Number(curr?.accountsReceivableAtms?.amount || 0),
                  0
                )
              )}
            </Typography.Text>
          }
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} align="end">
          <Typography.Text strong type="danger">
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(
              (dataSource || []).reduce(
                (prev, curr?: any) =>
                  prev + Number(curr?.accountsPayableAtms?.amount || 0),
                0
              )
            )}
          </Typography.Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
};

export const EditFormCashFlow: React.FC<any> = ({ onFinish, data }) => {
  const [form] = useForm<any>();
  useEffect(() => {
    form.setFieldsValue({
      ...data?.cashFlow,
      ...(data?.cashFlow?.accountsReceivable
        ? { accountsReceivable: data?.cashFlow?.accountsReceivable?.amount }
        : {}),
      ...(data?.cashFlow?.accountsPayable
        ? { accountsPayable: data?.cashFlow?.accountsPayable?.amount }
        : {}),
      supplier: data?.cashFlow?.accountsReceivable?.associate?.name || "",
      ...(data?.cashFlow?.financialCategory?._id
        ? { financialCategory: data?.cashFlow?.financialCategory?._id }
        : {}),
      ...(data?.cashFlow?.competenceMonth
        ? {
            competencePayment: dayjs(
              `${data?.cashFlow?.competenceMonth}/${data?.cashFlow?.competenceYear}`,
              "M/YYYY"
            ),
          }
        : {}),
        ...(data?.cashFlow?.accountsReceivableAtms
        ? { accountsReceivableAtms: data?.cashFlow?.accountsReceivableAtms }
        : {}),
        ...(data?.cashFlow?.accountsPayableAtms
        ? { accountsPayableAtms: data?.cashFlow?.accountsPayableAtms }
        : {}),
    });
  }, []);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={[8, 2]}>
        <Col span={24}>
          <Form.Item label="ID" name={"_id"} hidden>
            <Input placeholder="ID" disabled={true} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Título" name={"code"}>
            <Input placeholder="Título" disabled={true} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Data Entrada" name={"payDay"}>
            <DatePicker
              disabled={true}
              style={{ width: "100%" }}
              format={"DD/MM/YYYY"}
              placeholder="Data Entrada"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Competência do Pagamento"
            name={"competencePayment"}
          >
            <DatePicker
              format={"MM/YYYY"}
              picker="month"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Classificação Financeira"
            name={"financialCategory"}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={(data?.financialCategories || []).map((e: any) => ({
                value: e?._id,
                label: e?.description,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Fornecedor" name={"supplier"}>
            <Input placeholder="Fornecedor" disabled={true} />
          </Form.Item>
        </Col>
        <Divider type="horizontal" orientation="left">
          Geral
        </Divider>
        <Col span={12}>
          <Form.Item label="Entrada" name={"accountsReceivable"}>
            <NumericFormat
              disabled={true}
              displayType="input"
              thousandSeparator={"."}
              decimalScale={2}
              prefix="R$"
              decimalSeparator=","
              customInput={Input}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Saída" name={"accountsPayable"}>
            <NumericFormat
              disabled={true}
              renderText={(value: any) => {
                return 0;
              }}
              displayType="input"
              thousandSeparator={"."}
              decimalScale={2}
              prefix="R$"
              decimalSeparator=","
              customInput={Input}
            />
          </Form.Item>
        </Col>

        <Divider type="horizontal" orientation="left">
          ATMS
        </Divider>

        <Col span={12}>
          <Form.Item label="Entrada" name={"accountsReceivableAtms"}>
            <NumericFormat
              displayType="input"
              thousandSeparator={"."}
              decimalScale={2}
              prefix="R$"
              decimalSeparator=","
              customInput={Input}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Saída" name={"accountsPayableAtms"}>
            <NumericFormat
              renderText={(value: any) => {
                return 0;
              }}
              displayType="input"
              thousandSeparator={"."}
              decimalScale={2}
              prefix="R$"
              decimalSeparator=","
              customInput={Input}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item>
            <Flex justify="end">
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Salvar
              </Button>
            </Flex>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
