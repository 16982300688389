import {
  BackwardFilled,
  CheckOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tooltip,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash/debounce";
import { LabelWithButton } from "./style";
import { DetailsGrid } from "./DetailsGrid";
import dayjs from "dayjs";
import { BillingForm } from "./BillingForm";
import { api } from "../../api/api";

type CreateOrEditForm = {
  onFinish?: (values: any) => void;
  onFinalizeInsert?: (values: any) => void;
  dataSource: any;
};

export const CreateOrEditForm: React.FC<CreateOrEditForm> = ({
  onFinish,
  onFinalizeInsert,
  dataSource,
}) => {
  const [form] = useForm();
  const [data, setData] = useState<any>({});
  const [detail, setDetail] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = useCallback(
    debounce((inputName, e) => {
      onFinish?.(form.getFieldsValue());
      form.setFieldsValue({
        ...form.getFieldsValue(),
        [inputName]: e,
      });
    }, 500),
    []
  );

  const getDataDetails = () => {
    setLoading(true);
    new Promise((resolve, _) =>
      resolve(
        api.get(
          `/associate-monthly-billing-details?associateMonthlyBilling=${data?.associateMonthlyBilling?._id}`
        )
      )
    )
      .then(({ data: responseData }: any) => setDetails(responseData))
      .finally(() => setLoading(false));
  };

  const onFinishDetails = (content: any) => {
    if (!content?._id || content?._id === undefined) {
      new Promise((resolve, _) => {
        resolve(
          api.post(`/associate-monthly-billing-details`, {
            ...content,
            value: +String(content?.value)
              .replace("R$", "")
              .replace(".", "")
              .replace(",", "."),
          })
        );
      });
    } else {
      new Promise((resolve, _) => {
        resolve(
          api.put(`/associate-monthly-billing-details/${content?._id}`, {
            ...content,
            value: +String(content?.value)
              .replace("R$", "")
              .replace(".", "")
              .replace(",", "."),
          })
        );
      });
    }
    setShowModal(false);
    setDetail({});
    getDataDetails();
  };

  const onDeleteDetails = (content: any) => {
    new Promise((resolve, _) => {
      resolve(api.delete(`/associate-monthly-billing-details/${content?._id}`));
    }).then(() => {
      getDataDetails();
    });
  };

  const onEditDetails = (content: any) => {
    setDetail(content);
    setShowModal(true);
  };

  const onShowModal = () => {
    setShowModal(true);
    setDetail((prev: any) => ({
      ...prev,
      associateMonthlyBilling: form.getFieldValue("_id"),
    }));
  };

  useEffect(() => {
    setData(dataSource);
  }, []);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      form.setFieldsValue({
        ...data?.associateMonthlyBilling,
        ...(data?.associateMonthlyBilling?.associate?._id
          ? { associate: data?.associateMonthlyBilling?.associate?._id }
          : {}),
        ...(data?.associateMonthlyBilling?.startDate
          ? { startDate: dayjs(data?.associateMonthlyBilling?.startDate, "YYYY-MM-DD") }
          : {}),
        ...(data?.associateMonthlyBilling?.endDate
          ? { endDate: dayjs(data?.associateMonthlyBilling?.endDate, "YYYY-MM-DD") }
          : {}),
        associateCode: data?.associateMonthlyBilling?.associateCode,
      });

      new Promise((resolve, _) => {
        resolve(getDataDetails());
      });
    }
  }, [data]);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={[8, 2]}>
        <Col span={24}>
          <Flex justify="end" gap={2}>
            <Button
              type="primary"
              icon={<CheckOutlined />}
              onClick={() =>
                onFinalizeInsert!({
                  _id: form.getFieldValue("_id"),
                  finalized: true,
                })
              }
              loading={loading}
            >
              Finalizar
            </Button>
          </Flex>
        </Col>
        <Col span={24}>
          <Form.Item label="Id" name={"_id"} hidden>
            <Input placeholder="Identificador" />
          </Form.Item>
        </Col>
        {/* <Col span={4}> */}
        <Col xs={24} sm={24} md={12} lg={4} xl={4}>
          <Form.Item label="Nº Controle" name={"controlNumber"}>
            <Input
              placeholder="Nº Controle"
              onChange={(e) =>
                handleInputChange(
                  "controlNumber",
                  form.getFieldValue("controlNumber")
                )
              }
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={5} xl={5}>
          <Form.Item
            label="Data Início"
            name={"startDate"}
            rules={[{ required: true, message: "Preenchimento obrigatório" }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              format="DD/MM/YYYY"
              placeholder="Data Início"
              onChange={() =>
                handleInputChange("date", form.getFieldValue("date"))
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={5} xl={5}>
          <Form.Item
            label="Data Fim"
            name={"endDate"}
            rules={[{ required: true, message: "Preenchimento obrigatório" }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              format="DD/MM/YYYY"
              placeholder="Data Fim"
              onChange={() =>
                handleInputChange("date", form.getFieldValue("date"))
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={18} xl={18}>
          <Form.Item
            label="Associado"
            name={"associate"}
            rules={[{ required: true, message: "Preenchimento obrigatório" }]}
          >
            <Select
              placeholder="Associado"
              showSearch
              onChange={(value, option: any) => {
                handleInputChange("associate", form.getFieldValue("associate"));
                form.setFieldValue(
                  "associateCode",
                  data?.associates?.find((e: any) => e?._id === option?.value)
                    ?.code
                );
              }}
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={data?.associates?.flatMap((e: any) => [
                { value: e?._id, label: e?.name },
              ])}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item label="CNPJ" name={"associateCode"}>
            <Input placeholder="CNPJ" disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={24} xl={24}>
          <Form.Item
            label="Veículos"
            name={"vehicles"}
            rules={[{ required: true, message: "Preenchimento obrigatório" }]}
          >
            <Select
              showSearch
              mode="multiple"
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={() =>
                handleInputChange("vehicles", form.getFieldValue("vehicles"))
              }
              options={data?.vehicles?.flatMap((e: any) => [
                { value: e?._id, label: e?.code },
              ])}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label={
              <LabelWithButton>
                <span></span>
                <Tooltip title="Adicionar Despesas">
                  <Button
                    loading={loading}
                    size="small"
                    type="primary"
                    htmlType="button"
                    onClick={onShowModal}
                    icon={<PlusOutlined />}
                  />
                </Tooltip>
              </LabelWithButton>
            }
          >
            <DetailsGrid
              loading={loading}
              handleDelete={onDeleteDetails}
              handleEdit={onEditDetails}
              dataSource={details}
            />
          </Form.Item>
        </Col>
      </Row>
      <Modal
        destroyOnClose={true}
        title="Adicionar Despesa"
        open={showModal}
        onCancel={() => {
          setShowModal(false);
          setDetail({});
        }}
        footer={null}
      >
        <BillingForm
          data={{
            detail: detail,
            suppliers: data?.suppliers,
          }}
          onFinish={onFinishDetails}
        />
      </Modal>
    </Form>
  );
};
