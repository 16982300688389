import { SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Transfer,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { MaskedInput } from "antd-mask-input";
import { STATUS_EMPLOYEE } from "../../constants/status-employee";

type CreateOrEditForm = {
  onFinish?: (values: any) => void;
  data: any;
};
export const CreateOrEditForm: React.FC<CreateOrEditForm> = ({
  onFinish,
  data,
}) => {
  const [form] = useForm();
  useEffect(() => {
    form.setFieldsValue({
      ...data?.employee,
      ...(data?.employee?.position?._id
        ? { position: data?.employee?.position?._id }
        : {}),
      ...(data?.employee?.associate?._id
        ? { associate: data?.employee?.associate?._id }
        : {}),
      birthDate: data?.employee?.birthDate
        ? dayjs(data?.employee?.birthDate, "YYYY-MM-DD")
        : "",
      receptionDate: data?.employee?.receptionDate
        ? dayjs(data?.employee?.receptionDate, "YYYY-MM-DD")
        : "",
      driveLicenseValidity: data?.employee?.driveLicenseValidity
        ? dayjs(data?.employee?.driveLicenseValidity)
        : "",
    });
  }, []);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={[8, 2]}>
        <Col span={24}>
          <Form.Item label="Id" name={"_id"} hidden>
            <Input placeholder="Identificador" />
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item
            label="Nome"
            name={"fullname"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <Input placeholder="Nome" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Telefone" name={"cellPhone"}>
            <Input placeholder="Telefone" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="CPF"
            name={"code"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <Input placeholder="Cpf" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="RG"
            name={"registerNumber"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <Input placeholder="RG" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="CNH"
            name={"driveLicense"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <Input placeholder="Número CNH" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Categoria CNH"
            name={"driveLicenseCategory"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <Input placeholder="Categoria CNH" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Valdiade CNH"
            name={"driveLicenseValidity"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Data Nascimento"
            name={"birthDate"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Sexo"
            name={"gender"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={[
                { value: "M", label: "Masculino" },
                { value: "F", label: "Feminino" },
              ]}
            />
          </Form.Item>
        </Col>
        <Divider />

        <Col span={12}>
          <Form.Item
            label="Data Admissão"
            name={"receptionDate"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Função"
            name={"position"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={data?.positions?.flatMap((e: any) => [
                { value: e?._id, label: e?.description },
              ])}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Associado" name={"associate"}>
            <Select
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={data?.associates?.flatMap((e: any) => [
                { value: e?._id, label: e?.name },
              ])}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Situação" name={"status"}>
            <Select
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={STATUS_EMPLOYEE}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Flex justify="end">
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Salvar
              </Button>
            </Flex>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
