import _ from "lodash";
import dayjs from "dayjs";
import moment from "moment";
import { api } from "../../api/api";
import { useEffect, useState } from "react";
import { Card } from "../../components/card";
import Drawer from "../../components/drawer";
import { NumberOutlined } from "@ant-design/icons";
import { useSecurity } from "../../hooks/useSecurity";
import { Col, message, Row, Spin, Statistic, Table } from "antd";
import { Container } from "../../components/container";
import { PageHeader } from "../../components/page-header";
import { SearchForm } from "../../forms/accountsPayable/SearchForm";
import {
  ColumnsTable,
  TableSummary,
} from "../../constants/pages/accountsReceivable";
import { NoUndefinedRangeValueType } from "rc-picker/lib/PickerInput/RangePicker";
import { CreateOrEditForm } from "../../forms/accountsReceivable/CreateOrEditForm";
import { useDrawer } from "../../hooks/useDrawer";
import { exportExcel } from "../../utils/export-excel";
import { Title } from "./style";

const AccountsReceivableResumeGeneral: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [file, setFile] = useState<any>({});
  const [roles, setRoles] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [associates, setAssociates] = useState<any[]>([]);
  const [accountReceivable, setAccountReceivable] = useState<any>();
  const [accountReceivables, setAccountReceivables] = useState<any[]>([]);
  const [financialCategories, setFinancialCategories] = useState<any[]>([]);
  const [documentTypes, setDocumentTypes] = useState<any[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<any[]>([]);

  const [params, setParams] = useState<any>({
    competence: "",
    financialCategory: "",
    startIssueDate: "",
    endIssueDate: "",
    startDueDate: "",
    endDueDate: "",
    status: "",
  });

  const { getPermissions } = useSecurity();
  const { setIsOpen, setTitle, setContent, setClose } = useDrawer();

  const getData = (filter?: any) => {
    setLoading(true);
    new Promise((resolve, _) =>
      resolve(
        api.get(`/accounts-receivable/report`, {
          params: filter,
        })
      )
    )
      .then(({ data }: any) => {
        setAccountReceivables(data);
      })
      .finally(() => setLoading(false));
  };

  const getAssociates = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/associates`)))
      .then(({ data }: any) => {
        setAssociates(data);
      })
      .finally(() => setLoading(false));
  };

  const getDocumentTypes = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/paymentTypes`)))
      .then(({ data }: any) => {
        setPaymentTypes(data);
      })
      .finally(() => setLoading(false));
  };

  const getPaymentTypes = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/documentTypes`)))
      .then(({ data }: any) => {
        setDocumentTypes(data);
      })
      .finally(() => setLoading(false));
  };

  const getFinancialCategories = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/financialCategories`)))
      .then(({ data }: any) => {
        setFinancialCategories(data);
      })
      .finally(() => setLoading(false));
  };

  const onSearch = (data: any) => {
    const serialized = {
      ...(data?.competence
        ? { competence: dayjs(data?.competence).format("MM/YYYY") }
        : {}),
      ...(data?.startIssueDate
        ? { startIssueDate: dayjs(data?.startIssueDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.endIssueDate
        ? { endIssueDate: dayjs(data?.endIssueDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.startDueDate
        ? { startDueDate: dayjs(data?.startDueDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.endDueDate
        ? { endDueDate: dayjs(data?.endDueDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.startPaymentDate
        ? {
            startPaymentDate: dayjs(data?.startPaymentDate).format(
              "YYYY-MM-DD"
            ),
          }
        : {}),
      ...(data?.endPaymentDate
        ? { endPaymentDate: dayjs(data?.endPaymentDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.status ? { status: data?.status } : {}),
      ...(data?.financialCategory
        ? { financialCategory: data?.financialCategory }
        : {}),
    };
    setParams((prev: any) => ({ ...prev, serialized }));
    getData(serialized);
  };

  const onReset = () => {
    setLoading(true);
    setParams({
      competence: "",
      financialCategory: "",
      startIssueDate: "",
      endIssueDate: "",
      startDueDate: "",
      endDueDate: "",
      status: "",
    });
    setLoading(false);
    getData();
  };

  const onSubmit = (content: any) => {
    new Promise((resolve, _) => {
      if (content?._id) {
        resolve(
          api.put(`/accounts-receivable/${content?._id}`, {
            ...content,
            ...(content?.competence
              ? {
                  competenceMonth: dayjs(content?.competence).month() + 1,
                  competenceYear: dayjs(content?.competence).year(),
                }
              : {}),
            amount: String(content?.amount)
              .replace("R$", "")
              .replace(".", "")
              .replace(",", "."),
          })
        );
      } else {
        resolve(
          api.post(`/accounts-receivable`, {
            ...content,
            ...(content?.competence
              ? {
                  competenceMonth: dayjs(content?.competence).month() + 1,
                  competenceYear: dayjs(content?.competence).year(),
                }
              : {}),
            amount: String(content?.amount)
              .replace("R$", "")
              .replace(".", "")
              .replace(",", "."),
          })
        );
      }
    })
      .then(() => {
        new Promise((resolve, _) => resolve(getData()));
        setAccountReceivable({});
        setOpen(false);
      })
      .finally(() => {
        setLoading(false);
        setClose(true);
      });
  };

  const onExportExcel = () => {
    setLoading(true);
    try {
      exportExcel(
        accountReceivables.map((e) => {
          return {
            associate: e?.associate?.name,
            amount: new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(e.amount),
          };
        }),
        "Resumo Geral de Contas A Receber"
      );
      message.info("Exportação em andamento...");
    } catch (error) {
      message.error("Falha ao exportar. Tente novamente");
      return;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    new Promise((resolve, _) => resolve(getDocumentTypes()));
    new Promise((resolve, _) => resolve(getFinancialCategories()));
    new Promise((resolve, _) => resolve(getPaymentTypes()));
    new Promise((resolve, _) => resolve(getAssociates()));
    new Promise((resolve, _) => resolve(getData()));
  }, []);

  useEffect(() => {
    const role = getPermissions({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissions]);

  return (
    <Container>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <PageHeader
            title="Resumo Geral de Contas A Receber"
            roles={roles}
            // onClickAdd={handleAdd}
            onExportExcel={onExportExcel}
          />
        </Col>

        <Col span={24}>
          <SearchForm
            onFinish={onSearch}
            onReset={onReset}
            data={{ financialCategories, associates }}
          />
        </Col>

        <Col span={24}>
          <Table
            loading={loading}
            bordered
            rowKey={"_id"}
            size="small"
            columns={ColumnsTable({
              onSubmit,
              roles: {
                delete: false,
                edit: false,
              },
            })}
            dataSource={accountReceivables}
            summary={() => TableSummary({ dataSource: accountReceivables })}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AccountsReceivableResumeGeneral;
