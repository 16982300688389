import { Button, Col, DatePicker, Flex, Form, Input, Row } from "antd";
import { FormContentButtons } from "../../pages/companies/style";
import { MaskedInput } from "antd-mask-input";
import { useForm } from "antd/lib/form/Form";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

type SearchFormProp = {
  onFinish?: (values: any) => void;
};
export const SearchForm: React.FC<SearchFormProp> = ({ onFinish }) => {
  const [form] = useForm();

  return (
    <div
      style={{
        border: "1px solid #c6c66c6",
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ minWidth: "100%" }}
      >
        <Row gutter={[8, 2]}>
          <Col xs={24} sm={12} md={12} lg={5}>
            <Form.Item label="Data Início" name={"startDate"} initialValue={dayjs().startOf("month")}>
              <DatePicker
                placeholder="DD/MM/YYYY"
                style={{ width: "100%" }}
                format={"DD/MM/YYYY"}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={5}>
            <Form.Item label="Data Fim" name={"endDate"} initialValue={dayjs()}>
              <DatePicker
                placeholder="DD/MM/YYYY"
                style={{ width: "100%" }}
                format={"DD/MM/YYYY"}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={14}>
            <Form.Item label="Número de Controle" name={"controlNumber"}>
              <Input placeholder="Número de Controle" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item label="Associado" name={"associate"}>
              <Input placeholder="Associado" />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item label="Veículo" name={"vehicle"}>
              <Input placeholder="Veículo" />
            </Form.Item>
          </Col> */}

          <Col span={24}>
            <FormContentButtons>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<ClearOutlined />}
                    onClick={() => form.resetFields()}
                  >
                    Limpar
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SearchOutlined />}
                  >
                    Pesquisar
                  </Button>
            </FormContentButtons>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
